import React from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { IoMdContrast } from "react-icons/io"

const PageHeaderStyled = styled.h1`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 1rem;
  letter-spacing: 1;
  word-wrap: break-word;
  @media all and (max-width: 980px) {
    font-size: 32px;
  }
  @media all and (max-width: 680px) {
    font-size: 26px;
  }
`

const PageHeader = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Underline-2-grey.png" }) {
        childImageSharp {
          fixed(width: 143) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <PageHeaderStyled>{props.children}</PageHeaderStyled>
      <Img fixed={data.logo.childImageSharp.fixed} />
    </>
  )
}

export { PageHeader }
