import React from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { PageHeader } from "../components/Headings 1"
import { SubSectionHeader } from "../components/Headings 2"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Img from "gatsby-image"
import "./blog.scss"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import GetTheSpecialButton from "../components/GetTheSpecialButton"
import SpecialExtras from "../components/SpecialExtras"



const SpecialWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 50px;
  h4 {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-size: 18px;
    span {
      font-size: 34px;
    }
    margin-top: 0;
  }
  button {
    text-align: left;
  }
  p {
    text-align: left;
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    line-height: 1.5;
    &.center {
      text-align: center;
      margin-bottom: 0;
    }
    img {
      cursor: pointer;
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }    
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  .imgcol, .detcol {
    margin: 0 30px;
  }
  .imgcol {
    min-width: 56%;
    .grid {
      width: 100%;      
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .imgwrapper {
      width: calc(50% - 20px);
      padding: 10px;
    }
  }
  detcol {
    min-width: 280px;
  }
  @media all and (max-width: 1200px) {
    flex-direction: column;
    .imgcol, .detcol {
      width: 100%
      margin: 0;
    }
  }

`

const Description = styled.div`
  p {
    font-size: 16px;
  }
  text-align: left;

`

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => (
  <p>{children}</p>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

export default ({ data }) => {
  
  console.log(data.contentfulSpecial);

  return (
    <PageLayout>
      <SEO title={data.contentfulSpecial.seoTitle} description={data.contentfulSpecial.metaDescription} />
        <TextSectionBackground pa="4% 10% 2%">
          <SpecialWrapper>
            <PageHeader>
              {data.contentfulSpecial.header}
            </PageHeader>
            <SubSectionHeader style={{fontSize:"18px"}}>
              This exclusive special is only available through Mavela directly. Tap "Get the Special" and we'll sort everything out.
            </SubSectionHeader>
            <ColumnWrapper>
              <div className="imgcol">
                <div className="grid">
                  <div className="imgwrapper">
                    <Img fluid={data.contentfulSpecial.headerPic.fluid}></Img>
                  </div>
                  <div className="imgwrapper">
                    <Img fluid={data.food.childImageSharp.fluid}></Img>
                  </div>
                  <div className="imgwrapper">
                    <Img fluid={data.reception.childImageSharp.fluid}></Img>
                  </div>
                  <div className="imgwrapper">
                    <Img fluid={data.luxurytent.childImageSharp.fluid}></Img>
                  </div>
                </div>
                
                
              </div>
              <div className="detcol">
                <Description>
                  <div dangerouslySetInnerHTML={{
                  __html: '<h4>' + data.contentfulSpecial.price.price + '</h4>' }}/>
                  <GetTheSpecialButton />
                  {documentToReactComponents(
                    data.contentfulSpecial.description.json,
                    options
                  )}
                </Description>
              </div>              
            </ColumnWrapper>                     
          </SpecialWrapper>
        </TextSectionBackground>
        <TextSectionBackground pa="4% 10%" >
          <SpecialWrapper>
            <SpecialExtras />
          </SpecialWrapper>         
        </TextSectionBackground>
    </PageLayout>
  )
}

export const query = graphql`
  query SpecialByID($slug: String) {
    contentfulSpecial(slug: { eq: $slug }) {
      header
      price {
        price
      }
      seoTitle
      metaDescription
      description {
        json
      }
      headerPic {
        fluid(maxWidth: 920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allContentfulSpecial {
      nodes {
        header
        slug
        picture {
          fixed(width: 300, height: 200) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
    food: file(relativePath: { eq: "Mavela_Game_Lodge_048_preview-1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    reception: file(relativePath: { eq: "Mavela_Game_Lodge_002.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    luxurytent: file(relativePath: { eq: "Mavela-36.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
