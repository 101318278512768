import React from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { IoMdContrast } from "react-icons/io"

const FadeInKeyFrame = keyframes`
	0%{
		opacity: 0
	}
	100%{
		opacity: 1
	}
`

const FadeInHeaderStyled = styled.h1`
  font-size: 58px;
  line-height: 41px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  font-family: "Playfair Display";
`

const FadeInSubHeaderStyled = styled.h4`
  font-size: 17px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  font-style: italic;
`

const HeaderContainer = styled.div`
  text-align: center;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
  animation: ${FadeInKeyFrame} 1s ease-in-out;
`

const FadeInHeader = ({ header, subHeader }: any) => {
  return (
    <HeaderContainer>
      <FadeInHeaderStyled>{header}</FadeInHeaderStyled>
      <FadeInSubHeaderStyled>{header}</FadeInSubHeaderStyled>
    </HeaderContainer>
  )
}

const SectionHeaderStyled = styled.h2`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 1rem;
  letter-spacing: 1;
  word-wrap: break-word;
  @media all and (max-width: 980px) {
    font-size: 32px;
  }
  @media all and (max-width: 680px) {
    font-size: 26px;
  }
`

const SectionHeader = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Underline-2-grey.png" }) {
        childImageSharp {
          fixed(width: 143) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SectionHeaderStyled>{props.children}</SectionHeaderStyled>
      <Img fixed={data.logo.childImageSharp.fixed} />
    </>
  )
}

const SubSectionHeader = styled.h5`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-size: 25px;
  line-height: 1.4em;
  font-weight: 500;
  color: #323232;
  @media all and (max-width: 980px) {
    font-size: 20px;
  }
`

export { FadeInHeader, SectionHeader , SubSectionHeader }
