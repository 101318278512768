import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ImageRow from "../components/ImageRow"

const SpecialExtras = () => {
    const {
        contentfulSpecialDetailPage: { line },
      } = useStaticQuery(graphql`
        {
          contentfulSpecialDetailPage {
            line {
              header
              description {
                json
              }
              imageDesction {
                fluid(maxWidth: 610, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      `)
    return (
        <div>
            {line.map((about: any, i: number) => (
            <ImageRow
            key={about.header}
            pic={about.imageDesction.fluid}
            header={about.header}
            text={about.description.json}
            ></ImageRow>
            ))}    
        </div>
    )
    
}

export default SpecialExtras